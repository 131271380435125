import { useRef, useState } from "react"
import { Cell } from "./cell"
import { Modal } from "./modal"
import ReactSwitch from "react-switch"
import { createOrder } from "../api/api"
import Cookies from "js-cookie"
import { Oval } from "react-loader-spinner"
import { PhoneInput } from "./phoneInput"


export const Timetable = ({ schedule }) => {

    const [activeForm, setActiveForm] = useState(false)
    const [activeSuccess, setActiveSuccess] = useState(false)

    const [currentLesson, setCurrentLesson] = useState({})
    const [checked, setChecked] = useState(false)

    const [loading, setLoading] = useState(false)

    const clientInfo = localStorage.getItem('clientInfo') ? JSON.parse(localStorage.getItem('clientInfo')).personalInfo : {}


    const [name, setName] = useState(clientInfo?.fio?.split(' ')[0])
    const [surname, setSurname] = useState(clientInfo?.fio?.split(' ')[1])
    const [email, setEmail] = useState(clientInfo?.email)
    const [phoneNumber, setPhoneNumber] = useState(clientInfo?.tel)

    const [childAge, setChildAge] = useState('')

    console.log(currentLesson)
    console.log(schedule)

    const send = async (e) => {
        e.preventDefault()
        const regex = /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/;
        if (regex.test(e.target.phoneNumber.value)) {
            setLoading(true)
            const regExp = /[^0-9]/g
            const data = new FormData(e.target)
            data.set('phoneNumber', e.target.phoneNumber.value.replace(regExp, ''))
            const inputs = data.entries()
            const orderObj = {}
            for (let input of inputs) {
                orderObj[input[0]] = input[1]
            }
            orderObj.clientId = clientInfo.guid
            orderObj.lessonId = currentLesson.doc_ID
            orderObj.lessonDate = currentLesson.dateBeg
            if (checked) {
                orderObj.amount = data.get('childrenAge') > 6 ? currentLesson.prices.find(el => el.ageGroup_Name === 'Взрослые').cost : currentLesson.prices.find(el => el.ageGroup_Name === 'Дети до 7 лет').cost
            } else {
                orderObj.amount = currentLesson.prices.find(el => el.ageGroup_Name === 'Взрослые').cost
            }


            const response = await createOrder(orderObj)
            if (response.success) {
                window.location.href = response.formURL
            }
            console.log(response)
        } else {
            alert('Введите корректный номер телефона')
        }

    }

    return (
        <>
            <Modal active={activeSuccess} setActive={setActiveSuccess} >
                <h2>Вы успешно записались!</h2>
            </Modal>
            <Modal active={activeForm} setActive={setActiveForm} >
                {
                    loading ?
                        <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'gap': '15px', 'width': '100%', 'flexWrap': 'wrap' }}>
                            <Oval
                                visible={true}
                                height="80"
                                width="80"
                                color="#ff7f00"
                                secondaryColor='#ff7f00'
                                ariaLabel="loading"
                            />
                        </div>
                        :
                        <>

                            <h2 style={{ fontSize: '28px' }}>Записаться на занятие к инструктору</h2>
                            <form onSubmit={send}>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                                        <span style={{ color: 'black' }}>Записываю ребенка (до 17 лет включительно)</span>
                                        <ReactSwitch checked={checked} onChange={() => setChecked(!checked)} onColor="#fc9a40" checkedIcon={false} uncheckedIcon={false} />
                                    </div>
                                    <span style={{ textAlign: 'left', fontWeight: '500', color: 'black', fontSize: '22px' }}>Заполните данные {checked ? 'родителя' : ''}:</span>
                                    <input type="text" placeholder="Фамилия*" name="surname" value={name} onChange={(e) => setName(e.target.value)} required />
                                    <input type="text" placeholder="Имя*" required name="firstname" value={surname} onChange={(e) => setSurname(e.target.value)} />
                                    <input type="text" placeholder="Отчество" name="middlename" />
                                    <PhoneInput value={phoneNumber} onChange={(e) => {
                                        setPhoneNumber(e.target.value)
                                    }} />
                                    <input type="email" placeholder="Email*" required name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    {/* <span style={{ textAlign: 'left', fontWeight: '500', color: 'black', fontSize: '22px' }}>Где вам удобнее получать информацию?*</span>
                                    <div class="custom-select">
                                        <select required name="notifyService">
                                            <option value="mail">Почта</option>
                                            <option value="whatsapp">WhatsApp</option>
                                        </select>
                                    </div> */}
                                    <select style={{ display: 'none' }} required name="notifyService">
                                        <option value="mail">Почта</option>
                                        <option value="whatsapp">WhatsApp</option>
                                    </select>
                                    {
                                        checked ?
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                                                <span style={{ textAlign: 'left', fontWeight: '500', color: 'black', fontSize: '22px' }}>Заполните данные ребенка:</span>
                                                <input type="text" placeholder="Фамилия и имя ребенка*" name="childrenName" required />
                                                <input onChange={(e) => setChildAge(e.target.value)} type="number" placeholder="Возраст ребенка*" name="childrenAge" required />
                                            </div>
                                            : null
                                    }
                                    <div style={{ border: '2px solid #fc9a40', borderRadius: '20px', padding: '20px', textAlign: 'left' }}>
                                        <span style={{ fontWeight: '500', color: 'black', fontSize: '16px' }}>
                                            Вы записываетесь на {currentLesson?.exerciseSort?.toLowerCase()} на {currentLesson?.kindOfSport?.toLowerCase()} {currentLesson?.dateBeg?.split(' ')[0]} c {currentLesson?.dateBeg?.split(' ')[1]} по {currentLesson?.dateEnd?.split(' ')[1]}<br />
                                            Инструктор: {currentLesson?.trainer_Name}<br />
                                            Итого: {
                                                checked ?
                                                    (
                                                        childAge > 6 ?
                                                            currentLesson?.prices?.find(el => el.ageGroup_Name === 'Взрослые').cost + 'p.' :
                                                            currentLesson?.prices?.find(el => el.ageGroup_Name === 'Дети до 7 лет').cost + 'p.'
                                                    ) :
                                                    currentLesson?.prices?.find(el => el.ageGroup_Name === 'Взрослые').cost + 'p.'

                                            }
                                        </span>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <input id="agreement" type="checkbox" required style={{ marginRight: '10px' }} />
                                        </div>
                                        <span style={{ textAlign: 'left' }}>
                                            Согласен с обработкой персональных данных в соответствии с <a href="https://yes35.ru/privacy-policy.html" style={{ color: '#fc9a40' }}>политикой конфиденциальности</a>
                                        </span>
                                    </div>
                                    <button type="submit">Записаться</button>
                                </div>
                            </form>
                        </>
                }
            </Modal>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 15, width: '100%', flexWrap: 'wrap' }}>
                {schedule ? schedule.map(el => (
                    <>
                        <Cell lesson={el} key={el.doc_ID + 1} active={activeForm} setActive={setActiveForm} setCurrentLesson={setCurrentLesson} />
                    </>
                ))
                    : <p>На сегодня нет записи</p>}
            </div>
        </>
    )
}