import React from "react"

export const Modal = ({ active, setActive, children }) => {
    if (active){
        document.body.style.overflow = "hidden"
    } else {
        document.body.style.overflow = "scroll"
    }
    return (
        <div className={`modal__background ${active ? 'showed' : ''}`}>
            <div className={`modal-lesson ${active ? 'showed' : ''}`}>
                <div className="modal__close" onClick={() => setActive(!active)}>
                    <svg
                        fill="none"
                        height="14"
                        viewBox="0 0 14 14"
                        width="14"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_295_12824)">
                            <path
                                d="M11.0518 0L7 4.05177L2.94823 0L0 2.94823L4.0518 7L0 11.0518L2.9482 14L7 9.9482L11.0518 14L14 11.0518L9.9482 6.99997L14 2.9482L11.0518 0Z"
                                fill="#8D8887"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_295_12824">
                                <rect
                                    fill="white"
                                    height="14"
                                    width="14"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                {children}
            </div>
        </div>
    )
}