import Cookie from "js-cookie";


const URL = 'https://testapicommon.yes35.ru:9999/v1/GLK/'


export const getTrainersList = async (date) => {
    const response = await fetch(URL + `getTrainerList?date=${date}`, {
        headers: {
            'Content-Type': 'application/json'
        } 
    })
    const data = await response.json()
    return data 
}

export const getTimetable = async (trainerId, date) => {
    const response = await fetch(URL + `getTimetable?trainerId=${trainerId}&date=${date}`, {
        headers: {
            'Content-Type': 'application/json'
        } 
        
    })
    const data = await response.json()
    return data 
}

export const getProfileInfo = async () => {
    const response = await fetch('https://testapi.yes35.ru:9972/v1/auth/getClientInfo', {
        credentials: "include",
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookie.get('token')}`
        }
    })
    const data = await response.json()
    return data
}

export const createOrder = async (order) => {
    const response = await fetch(URL + 'createOrder', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookie.get('token')}`
        },
        body: JSON.stringify(order)
    })
    const data = await response.json()
    return data
}
