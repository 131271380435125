export const Features = (props) => {
  return (
    <div id='features' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>Цены</h2>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.title}-${i}`} className='col-md-4 row__min-height'>
                {' '}
                <h3>{d.title}</h3>
                <h3 style={{ color: "red" }}>{d.holidayTitle}</h3>
                <p><b>{d.bdn}</b>{d.bdnprice}</p>
                <p><b>{d.wknd}</b>{d.wkndprice}</p>
                <p><b>{d.holiday}</b>{d.holidayprice}</p>

                <p>{d.text}</p>

              </div>
            ))
            : 'Loading...'}
        </div>
      </div>
    </div>
  )
}
