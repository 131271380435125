import Cookies from "js-cookie"

export const Navigation = (props) => {

  const cookie = Cookies.get('token')

  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>

        <div className='navbar-header'>

          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <a href='#page-top'>
            <img src="https://yes35.ru/templates/aqua/images/logo-yes35.png" width="60" height="60" alt="" />

          </a>{' '}
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <a href='#about' className='page-scroll'>
                О проекте
              </a>
            </li>


            <li>
              <a href='#services' className='page-scroll'>
                Инструкторы
              </a>
            </li>
            <li>
              <a href='#features' className='page-scroll'>
                Стоимость
              </a>
            </li>
            <li>
              <a href='#testimonials' className='page-scroll'>
                Программы
              </a>
            </li>
            <li>
              <a href='#portfolio' className='page-scroll'>
                Галерея
              </a>
            </li>
            <li>
              <a href='#contact' className='page-scroll'>
                Контакты
              </a>
            </li>
          </ul>
          {
            cookie ?
              <a
                className="header__login login-btn"
                href="https://lk.yes35.ru"
                style={{ marginLeft: 'auto' }}
              >
                <div className="header__login-icon" >
                  <svg
                    fill="none"
                    height="18"
                    viewBox="0 0 16 18"
                    width="16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.4167 17.25C14.4167 13.7062 11.5439 10.8333 8.00004 10.8333C4.45621 10.8333 1.58337 13.7062 1.58337 17.25M8.00004 8.08333C5.975 8.08333 4.33337 6.44171 4.33337 4.41667C4.33337 2.39162 5.975 0.75 8.00004 0.75C10.0251 0.75 11.6667 2.39162 11.6667 4.41667C11.6667 6.44171 10.0251 8.08333 8.00004 8.08333Z"
                      stroke="#FF7F00"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                  </svg>
                </div>
                <div className="header__login-text">
                  Профиль
                </div>
              </a>
              :
              <a
                className="header__login login-btn white"
                href="https://lk.yes35.ru"
                style={{ marginLeft: 'auto' }}
              >
                <div className="header__login-icon">
                  <svg
                    fill="none"
                    height="18"
                    viewBox="0 0 16 18"
                    width="16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.4167 17.25C14.4167 13.7062 11.5439 10.8333 8.00004 10.8333C4.45621 10.8333 1.58337 13.7062 1.58337 17.25M8.00004 8.08333C5.975 8.08333 4.33337 6.44171 4.33337 4.41667C4.33337 2.39162 5.975 0.75 8.00004 0.75C10.0251 0.75 11.6667 2.39162 11.6667 4.41667C11.6667 6.44171 10.0251 8.08333 8.00004 8.08333Z"
                      stroke="#FF7F00"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                  </svg>
                </div>
                <div className="header__login-text">
                  Войти
                </div>
              </a>
          }
        </div>
      </div>
    </nav>
  )
}
