import { useState } from "react"
import "../App.css"


export const Cell = ({lesson, active, setActive, setCurrentLesson}) => {
    const time = lesson?.dateBeg?.split(' ')[1].split(':')[0] + ':' + lesson?.dateBeg?.split(' ')[1].split(':')[1]
    console.log(lesson)

    return(
        <>
        <button className={`timeCell`} disabled={!lesson.isActive ? true : false} onClick={() => {
                setActive(!active)
                setCurrentLesson(lesson)
        }}>
            {time}
        </button>
        </>
    )
}