import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { Image } from "./components/image";
import { About } from "./components/about";
import { Gallery } from "./components/gallery";
import { Snowboard } from "./components/snowboard";
import { Ski } from "./components/ski";
import { Testimonials } from "./components/testimonials";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { getProfileInfo, getTrainersList } from "./api/api";
import { SportsTypes } from "./components/sportsTypes";
import Cookie from "js-cookie";
import { Modal } from "./components/modal";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [activeModal, setActiveModal] = useState(false)
  // const [data, setData] = useState({});

  const cookie = Cookie.get('token')

  const params = new URLSearchParams(window.location.search);
  

  const fetchInfo = async () => {
    const response = await getProfileInfo()
      localStorage.setItem('clientInfo', JSON.stringify(response.clientInfo))
      
}

  useEffect(() => {
    if (cookie) {
      fetchInfo()
    } else {
      localStorage.removeItem('clientInfo')
    }

    if(params.get('surname')) {
      console.log(params.get('surname'))
      setActiveModal(true)
    }
    setLandingPageData(JsonData);
}, []);
  return (
    <div>
      <Modal active={activeModal} setActive={setActiveModal}>
        <h2 style={{textAlign: 'center'}}>Перенести занятие с инструктором</h2>
        <span style={{ textAlign: 'left', fontWeight: '500', color: 'black', fontSize: '22px' }}>Выберите занятие:</span>
      </Modal>
      <Navigation />
      <Header data={landingPageData.Header} />
      <About data={landingPageData.About} />
      <SportsTypes  />
      <Features data={landingPageData.Features} />
      <Testimonials data={landingPageData.Testimonials} />
      <Gallery data={landingPageData.Gallery}/>
      <Image data={landingPageData.Image} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;
