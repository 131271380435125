import { format, useMask } from "@react-input/mask"


const options = {
    mask: '+_ (___) ___-__-__', replacement: { _: /\d/ }
}


export const PhoneInput = ({ className, value, onChange }) => {

    const inputRef = useMask({
        mask: '+_ (___) ___-__-__', replacement: { _: /\d/ }, track: ({ inputType, value, data, selectionStart, selectionEnd }) => {
            if (!value && data == 8) return `+7`
            else if (!value && data == 9) return `+7 (9`
        }
    });

    const formatValue = value ? format(value, options) : value



    return (
        <input
            defaultValue={formatValue}
            className={className}
            ref={inputRef}
            name="phoneNumber"
            placeholder="Телефон*"
            onChange={onChange}
            required
            type="tel"
        />
    );
}