import { useState } from "react"
import { Trainer } from "./trainer"

export const Ski = ({ trainers }) => {

  return (
        <div className='row'>
          {trainers ? trainers?.map(el => (
            <Trainer trainer={el} key={el.trainer_ID}/>
          )) : null}
        </div>
  )
}
