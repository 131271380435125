import { useEffect, useState } from "react"
import { Ski } from "./ski"
import { Snowboard } from "./snowboard"
import { getTrainersList } from "../api/api"


export const SportsTypes = () => {

    const [trainers, setTrainers] = useState([])
    const [active, setActive] = useState(false)

    const fetchTrainers = async () => {
        let date = new Date()
        date = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
        const data = await getTrainersList(date)
        setTrainers(data.trainers)
    }

    useEffect(() => {
        fetchTrainers()
    }, []);
    console.log(trainers)

    return (
        trainers.length > 0 ?
            <div id='services' className='text-center'>
                <div className='container'>
                    <div className='section-title'>
                        <h2>Записаться на занятия к инструктору</h2>
                        <div style={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
                            <span className={`title ${active ? 'active' : null}`} onClick={() => setActive(true)}>
                                Горные лыжи
                            </span>
                            <span className={`title ${!active ? 'active' : null}`} onClick={() => setActive(false)}>
                                Сноуборд
                            </span>
                        </div>
                    </div>
                    {
                        active ?
                            <Ski trainers={trainers?.filter(el => el.specialization?.some(el => el === "Горные лыжи"))} />
                            :
                            <Snowboard trainers={trainers?.filter(el => el.specialization?.some(el => el === "Сноуборд"))} />
                    }
                </div>
            </div>
            : null
    )
}