import { useState } from 'react'
import emailjs from 'emailjs-com'

const initialState = {
  name: '',
  email: '',
  message: '',
}
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
        'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID'
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
        },
        (error) => {
          console.log(error.text)
        }
      )
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Контакты</h2>
                <p>
                  <iframe src="https://yandex.ru/map-widget/v1/?ll=39.328961%2C59.179759&mode=poi&poi%5Bpoint%5D=39.312893%2C59.181399&poi%5Buri%5D=ymapsbm1%3A%2F%2Forg%3Foid%3D92238264079&z=15.2" width="100%" height="80%" frameborder="0"></iframe>
                </p>
              </div>

            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Контактная информация</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Адрес:
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Телефон
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>

            </div>

            <div className='contact-item'>
              <p>
                <span>
                  <i className=''></i> VK
                </span>{' '}
                <a href={props.data ? props.data.VK : 'loading'}>Центр развлечений Y.E.S.</a>
              </p>

            </div>

            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>

        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
          </p>
        </div>
      </div>
    </div>
  )
}
